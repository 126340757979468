body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.node{
    height:25px;
    width:25px;
    background-color: white;
    outline:1px solid rgb(175, 216, 248);
    display: inline-block;
}

.node-start{
    background-color: chartreuse;
}
.node-end{
    background-color: brown;
}
.node-visited{
    animation-name: visitedAnimation;
    animation-iteration-count: 1;
    animation-duration: 1.5s;
    animation-delay: 0;
    background-color: rgba(0, 190, 218, 0.75);
}

@keyframes visitedAnimation {
    0% {
        transform: scale(0.3);
        background-color: rgba(0, 0, 66, 0.75);
        border-radius: 100%;
    }

    50% {
        background-color: rgba(17, 104, 217, 0.75);
    }

    75% {
        transform: scale(1.2);
        background-color: rgba(0, 217, 159, 0.75);
    }

    100% {
        transform: scale(1);
        background-color: rgba(0, 190, 218, 0.75);
    }
}

.node-wall {
    background-color: black;
    outline: 1px solid black;
    animation-name: wallAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
}
@keyframes wallAnimation {
    0% {
        transform: scale(.3);
        background-color: rgb(12, 53, 71);
    }

    50% {
        transform: scale(1.2);
        background-color: rgb(12, 53, 71);
    }

    100% {
        transform: scale(1.0);
        background-color: rgb(12, 53, 71);
    }
}

.node-shortest-path {
    animation-name: shortestPath;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

@keyframes shortestPath {
    0% {
        transform: scale(0.6);
        background-color: rgb(255, 254, 106);
    }

    50% {
        transform: scale(1.2);
        background-color: rgb(255, 254, 106);
    }

    100% {
        transform: scale(1);
        background-color: rgb(255, 254, 106);
    }
}

.Grid{
    font-size: 0;
}
div{
    padding: 0px;
    margin: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.type{
    width: 100%;
    align-content: center;
    background-color: #282c34;
    text-align: center;
    font-family: "Roboto Slab ExtraLight";
}

.Cards{
    background-color: grey;
    align-items: center;
    align-self: center;
    align-content: center;
    position: center;
    child-align: middle;
}
.page-footer{
    background-color: #282c34;
}
.footer-copyright{
    color:white;
    background-color: black;
}
.greet{
    text-align: center;
    background-color: #434445;
    color: white;
    padding: 10px;
}

.cell{
    height:50px;
    width:50px;
    background-color: white;
    outline:1px solid rgb(175, 216, 248);
    display: inline-block;
    font-size: 25px;
    text-align: center;
    color: black;
    border-radius: 5px 5px;
}

.cell-prime{
    background-color: #00f51d;
    animation-name: isPrime;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-timing-function: linear;

}
@keyframes isPrime {
    0% {
        transform: rotate(90deg);
        background-color: rgba(0, 0, 66, 0.75);
        border-radius: 100%;
    }

    50% {
        background-color: rgba(17, 104, 217, 0.75);
        transform: scale(1.5);

    }

    75% {
        background-color: rgba(0, 217, 159, 0.75);
        transform: rotate(270deg);

    }

    100% {

        background-color: rgba(0, 190, 218, 0.75);
        transform: rotate(360deg);
    }
}
.cell-check{
    background-color: grey;
}
.cell-visiting{
    outline:3px solid rgb(175, 216, 248);
    animation-name: isVisiting;
    animation-duration: 1s;
}
@keyframes isVisiting {
    50%{
        transform: scale(1.5);
    }
}
.Cells{
    font-size: 0px;
}
.rect{
    background-color: steelblue;
    outline: 1px black;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin: 5px;
}
.boardCell{
    width:100px;
    height:100px;

    outline:2px solid #607d8b;
    display: inline-flex;
}
.checked{
    background-image: radial-gradient(circle, #b3e5fc,#29b6f6);
}
.attacked{
    background-image: radial-gradient(circle, #ef9a9a ,darkred);
}
.current{
    background-image: radial-gradient(circle, #fff176 ,#f57f17);
}
.present{
    background-image: radial-gradient(circle, #dce775 ,#43a047);
}
.booard{
    font-size: 0;
}
.rect{
    background-color: steelblue;
    outline: 1px black;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin: 5px;
}
