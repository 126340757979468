.node{
    height:25px;
    width:25px;
    background-color: white;
    outline:1px solid rgb(175, 216, 248);
    display: inline-block;
}

.node-start{
    background-color: chartreuse;
}
.node-end{
    background-color: brown;
}
.node-visited{
    animation-name: visitedAnimation;
    animation-iteration-count: 1;
    animation-duration: 1.5s;
    animation-delay: 0;
    background-color: rgba(0, 190, 218, 0.75);
}

@keyframes visitedAnimation {
    0% {
        transform: scale(0.3);
        background-color: rgba(0, 0, 66, 0.75);
        border-radius: 100%;
    }

    50% {
        background-color: rgba(17, 104, 217, 0.75);
    }

    75% {
        transform: scale(1.2);
        background-color: rgba(0, 217, 159, 0.75);
    }

    100% {
        transform: scale(1);
        background-color: rgba(0, 190, 218, 0.75);
    }
}

.node-wall {
    background-color: black;
    outline: 1px solid black;
    animation-name: wallAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
}
@keyframes wallAnimation {
    0% {
        transform: scale(.3);
        background-color: rgb(12, 53, 71);
    }

    50% {
        transform: scale(1.2);
        background-color: rgb(12, 53, 71);
    }

    100% {
        transform: scale(1.0);
        background-color: rgb(12, 53, 71);
    }
}

.node-shortest-path {
    animation-name: shortestPath;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

@keyframes shortestPath {
    0% {
        transform: scale(0.6);
        background-color: rgb(255, 254, 106);
    }

    50% {
        transform: scale(1.2);
        background-color: rgb(255, 254, 106);
    }

    100% {
        transform: scale(1);
        background-color: rgb(255, 254, 106);
    }
}