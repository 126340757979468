
.type{
    width: 100%;
    align-content: center;
    background-color: #282c34;
    text-align: center;
    font-family: "Roboto Slab ExtraLight";
}

.Cards{
    background-color: grey;
    align-items: center;
    align-self: center;
    align-content: center;
    position: center;
    child-align: middle;
}
.page-footer{
    background-color: #282c34;
}
.footer-copyright{
    color:white;
    background-color: black;
}
.greet{
    text-align: center;
    background-color: #434445;
    color: white;
    padding: 10px;
}
